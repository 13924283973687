import React from "react"
import ProfileRouteAnonymous from "../../../../../components/auth/ProfileRoute/Anonymous"
import ProposalPreview from "../../../../../components/Proposal/ProposalPreview"

const ProposalsPreviewAnonymousPage = props => (
  <ProfileRouteAnonymous>
    <ProposalPreview url={props.location.href} />
  </ProfileRouteAnonymous>
)

export default ProposalsPreviewAnonymousPage
